import { action, makeAutoObservable, observable } from "mobx";
import React from "react";
import { tokenMgr } from "../axios/axios";
import {
  LoginParams,
  UserInfoInterface,
} from "../api/apiInterface/chatInterface";
import * as Api from "../api/profile";
import Web3 from "web3";
import { getEthAccount, getUserInfoByJWT, isLogin } from "../../constant/utils";
import { PAGE_TYPE, PLATFORM_ENUM } from "../../constant/enum";
import moment from "moment";
import * as ChatApi from "../api/chat";
import { UserSettingType } from "../api/profile";
import {Client} from "web3-mq";

const size = 20;
// @ts-ignore
const web3 = new Web3(window.ethereum);
export default class AppStore {
  @observable showLoading: boolean = false;
  @observable userInfo: UserInfoInterface | null = null;
  @observable loginUserInfo: UserInfoInterface | null = getUserInfoByJWT();
  @observable pageType: PAGE_TYPE = PAGE_TYPE.PROFILE;
  @observable contacts: any[] = [];
  @observable currentContactsCount: number = 0;
  @observable followers: any[] = [];
  @observable currentFollowersCount: number = 0;
  @observable showModal: boolean = false;
  @observable userEmail: string = "";
  @observable isFollowed: boolean = false;
  @observable client: Client | null = null
  @observable userSetting: UserSettingType = {
    notification_settings: {
      mail: false,
      sms: false,
      web: false,
    },
    contact_settings: "friends",
  };

  @action setShowModal(data: boolean) {
    this.showModal = data;
  }

  @action setShowLoading(data: boolean) {
    this.showLoading = data;
  }

  @action setPageType(data: PAGE_TYPE) {
    this.pageType = data;
  }

  @action async setClient(data: Client) {
    this.client = data
  }

  @action
  async getUserSetting() {
    this.setShowLoading(true);
    try {
      const { data } = await Api.getUserSettings();
      this.userSetting.contact_settings = data.contact_settings
      this.userSetting.notification_settings = data.notification_settings
      this.setShowLoading(false);
    } catch (e) {
      this.setShowLoading(false);
    }
  }

  @action async updateUserSetting(params: UserSettingType) {
    this.setShowLoading(true)
    try {
      const { data } = await Api.updateUserSetting(params)
      await this.getUserSetting()
      this.setShowLoading(false)
    }catch (e) {
      console.log(e)
      this.setShowLoading(false)

    }
  }

  @action
  async getUserInfo(address: string) {
    this.setShowLoading(true);
    try {
      const { data } = await Api.getUserInfo({
        platform: "wallet",
        user_name: address,
      });
      this.userInfo = data;
      await this.getUserEmail(data.user_id);
      if (isLogin()) {
        await this.getMyRooms(data.user_id);
      }
      this.setShowLoading(false);
    } catch (e) {
      this.setShowLoading(false);
    }
  }

  @action
  async getUserEmail(userId: string) {
    this.setShowLoading(true);
    try {
      const { data } = await Api.getUserAccounts({
        accountType: "EMAIL",
        page: 1,
        size: 20,
        userId: userId,
      });
      if (data[0] && data[0].account_username) {
        this.userEmail = data[0].account_username;
      } else {
        this.userEmail = "";
      }
      this.setShowLoading(false);
    } catch (e) {
      this.userEmail = "";

      this.setShowLoading(false);
    }
  }

  @action setUserInfo(data: UserInfoInterface) {
    this.userInfo = data;
  }

  @action emptyUserInfo() {
    this.userInfo = null;
  }

  @action
  async getContacts(page: number = 1, size: number = 20) {
    this.setShowLoading(true);
    let oriChatData = [...this.contacts];
    if (page === 1) {
      oriChatData = [];
    }
    try {
      const { data } = await Api.getContacts({
        page,
        size,
      });
      this.currentContactsCount = data.length;
      this.contacts = [...oriChatData, ...data];
      this.setShowLoading(false);
    } catch (e) {
      this.setShowLoading(false);
    }
  }

  @action
  async getFollowers(page: number = 1, size: number = 20) {
    this.setShowLoading(true);
    let oriChatData = [...this.followers];
    if (page === 1) {
      oriChatData = [];
    }
    try {
      const { data } = await Api.getFollowers({
        page,
        size,
      });
      this.currentFollowersCount = data.length;
      this.followers = [...oriChatData, ...data];
      this.setShowLoading(false);
    } catch (e) {
      this.setShowLoading(false);
    }
  }

  @action
  async getMyRooms(userId: string) {
    this.setShowLoading(true);
    try {
      const { data } = await ChatApi.getMyRooms({
        user_id: userId,
      });
      this.isFollowed = !!data;
      this.setShowLoading(false);
    } catch (e) {
      this.setShowLoading(false);
    }
  }

  @action logout() {
    this.showLoading = false;
    this.loginUserInfo = null;
    tokenMgr().setToken("");
  }

  @action
  async login() {
    this.showLoading = true;
    let ethAccount = await getEthAccount();
    if (!ethAccount.address) {
      this.showLoading = false;
      return false;
    }
    let address = ethAccount.address;

    await Api.register({
      platform: PLATFORM_ENUM.OPENSEA,
      user_name: address,
    });

    let randomSecret = await Api.getLoginRandomSecret({
      wallet_address: address,
    });

    if (randomSecret.data) {
      const msg = `0x${Buffer.from(randomSecret.data, "utf8").toString("hex")}`;
      let signContent = `SwapChat wants you to sign in with your Ethereum account:
${address}
for SwapChat login
URI: https://chat.web3messaging.online/
Nonce: ${msg}
Issued At: ${moment().utc().local().format("DD/MM/YYYY HH:mm")}`;
      const res = await web3.eth.personal
        .sign(signContent, address, "swapchat")
        .catch((e: any) => {
          console.log(e);
          this.showLoading = false;
        });
      if (res) {
        let loginParams: LoginParams = {
          wallet_address: address,
          signature: res,
          login_random_secret: signContent,
        };
        const loginRes = await Api.login(loginParams);
        if (loginRes.code === 0) {
          const token = loginRes.data.access_token;
          tokenMgr().setToken(token);
          this.loginUserInfo = getUserInfoByJWT();
          // 登录成功后填充默认数据
          await this.getFollowers(1, size);
          await this.getContacts(1, size);
          // 登录成功表示判断是否关注当前用户
          if (this.userInfo) {
            await this.getMyRooms(this.userInfo.user_id);
          }
        }
      } else {
        this.showLoading = false;
      }
    } else {
      this.showLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this); //even though this isn't required in some examples, this seems key line to making mobx work
  }
}

export const StoreContext = React.createContext(new AppStore());
export const StoreProvider = StoreContext.Provider;
export const useStore = () => React.useContext(StoreContext);
