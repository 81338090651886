import React, { useCallback, useEffect, useState } from "react";
import ss from "./index.module.scss";
import {
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonModal,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import * as ChatApi from "../../services/api/chat";
import {
  getShortAddressByAddress,
  getUserAvatar,
  getUserInfoByJWT,
  getUserNickName,
} from "../../constant/utils";
import { PLATFORM_ENUM } from "../../constant/enum";
import cx from "classnames";
import copy from "copy-to-clipboard";
import { UserInfoInterface } from "../../services/api/apiInterface/chatInterface";
import ConnectAccountModal, {
  ACCOUNT_CONNECT_TYPE,
} from "../ConnectAccountModal";

const userIcon = "https://s3.us-west-2.amazonaws.com/videos.house.west2/SwapChat-static/svg/user.svg";
const sendMessageIcon = require("../../assert/svg/profileSendMessageIcon.svg").default;
const followIcon = require("../../assert/svg/followIcon.svg").default;
const copyIcon = require("../../assert/svg/copyIcon.svg").default;
const facebookIcon = require("../../assert/img/pre-facebook-icon.png").default;
const twitterIcon = require("../../assert/svg/twitterIcon.svg").default;
const openseaIcon = require("../../assert/svg/openseaIcon.svg").default;
const discordIcon = require("../../assert/svg/discordIcon.svg").default;
const insIcon = require("../../assert/img/pre-instagram-icon.png").default;
const nextIdIcon = require("../../assert/img/nextIdIcon.jpeg").default;
const profileENSIcon =
  "https://s3.us-west-2.amazonaws.com/videos.house.west2/SwapChat-static/svg/profileENSIcon.svg";
const profileDotBitIcon = require("../../assert/svg/profileDotBitIcon.svg").default;
const profileNameTagIcon = require("../../assert/svg/profileNameTagIcon.svg").default;
const isBusinessUserIcon = require("../../assert/svg/isBusinessUser.svg").default;
const emailIcon = require("../../assert/svg/emailIcon.svg").default;
const phoneIcon = require("../../assert/svg/phoneIcon.svg").default;
const connectMoreAccountIcon = require("../../assert/svg/connectMoreAccountIcon.svg").default;

const accountsConfigs = [
  {
    platform: PLATFORM_ENUM.TWITTER,
    defaultIcon: twitterIcon,
    title: "Twitter",
  },
  {
    platform: PLATFORM_ENUM.OPENSEA,
    defaultIcon: openseaIcon,
    title: "OpenSea",
  },
  {
    platform: PLATFORM_ENUM.DISCORD,
    defaultIcon: discordIcon,
    title: "Discord",
  },
  {
    platform: PLATFORM_ENUM.FACEBOOK,
    defaultIcon: facebookIcon,
    title: "Facebook",
  },
  {
    platform: PLATFORM_ENUM.INSTAGRAM,
    defaultIcon: insIcon,
    title: "Instagram",
  },
];

interface IAppProps {
  userInfo: UserInfoInterface;
  isMobile: boolean;
}

type dids = {
  nextId: string;
  ens: string;
  dotBit: string[];
  nameTag: string[];
};

const size = 30;
const MemberProfileModal: React.FC<IAppProps> = observer((props) => {
  const store = useStore();
  const [present] = useIonToast();
  const { userInfo, isMobile = false } = props;
  const [segmentValue, setSegmentValue] = useState("1");
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);
  const [userNFTs, setUserNFTs] = useState<any[]>([]);
  const [DIDs, setDIDs] = useState<dids>({
    nextId: "",
    ens: "",
    dotBit: [],
    nameTag: [],
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [pageType, setPageType] = useState<ACCOUNT_CONNECT_TYPE>(
    ACCOUNT_CONNECT_TYPE.PHONE
  );

  const queryDids = async () => {
    let dids: dids = {
      nextId: "",
      ens: "",
      dotBit: [],
      nameTag: [],
    };
    // next id
    if (userInfo.twitter_username) {
      let nextUser = await ChatApi.getNextIdUserInfo({
        platform: PLATFORM_ENUM.TWITTER,
        username: userInfo.twitter_username,
      });
      if (nextUser.ids[0] && nextUser.ids[0].persona) {
        dids.nextId = getShortAddressByAddress(nextUser.ids[0].persona);
      }
    }

    const nameTagData = await ChatApi.getUserNameTag({
      user_id: userInfo.user_id,
      wallet_address:
        process.env.REACT_APP_NODE_ENV === "development"
          ? "0x3a07908a0ac2e125dfd9bf5734b5f9309efb86dc"
          : userInfo.eth_wallet_address,
    });
    if (
      nameTagData.data &&
      nameTagData.data.nametags &&
      nameTagData.data.nametags.length > 0
    ) {
      nameTagData.data.nametags.forEach((item: any) => {
        if (item.name) {
          dids.nameTag.push(item.name as string);
        }
      });
    }

    const dotBitsData = await ChatApi.getUserDotBits({
      user_id: userInfo.user_id,
      wallet_address:
        process.env.REACT_APP_NODE_ENV === "development"
          ? "0x1d643fac9a463c9d544506006a6348c234da485f"
          : userInfo.eth_wallet_address,
    });
    if (dotBitsData.data && dotBitsData.data.result) {
      let result = dotBitsData.data.result;
      if (
        result.data &&
        result.data.account_list &&
        result.data.account_list.length > 0
      ) {
        result.data.account_list.forEach((item: any) => {
          if (item.account) {
            dids.dotBit.push(item.account as string);
          }
        });
      }
    }
    setDIDs(dids);
  };
  const getUserNFTs = async (page: number = 1) => {
    const nftData = await ChatApi.getUserNFTList({
      user_id: userInfo.user_id,
      wallet_address:
        process.env.REACT_APP_NODE_ENV === "development"
          ? "0xa99a76dddbb9678bc33f39919bc76d279c680c89"
          : userInfo.eth_wallet_address,
      page: page,
      size,
    });
    if (nftData.data) {
      if (nftData.data.assets.length < size) {
        setIsInfiniteDisabled(true);
      }
      if (page === 1) {
        setUserNFTs(nftData.data.assets);
      } else {
        setUserNFTs(nftData.data.assets.concat(userNFTs));
      }
    }
  };

  const loadData = async (ev: any) => {
    let currentPage = (userNFTs.length / size).toFixed(0);
    let item = +currentPage + 1;
    await getUserNFTs(item);
    ev.target.complete();
  };

  useEffect(() => {
    const init = async () => {
      await queryDids();
      await getUserNFTs();
    };
    init();
  }, []);

  useIonViewWillEnter(() => {
    // 处理tab1白屏问题
    let errClassStr = "ion-page-invisible";
    let ele: any = document.querySelector(".member-profile-modal-page");
    if (ele && ele.className) {
      let str = ele.className;
      let index = str.indexOf(errClassStr);
      if (index > -1) {
        ele.className = str.replace(errClassStr, "");
      }
    }
  });

  const RenderDidsList = useCallback(() => {
    return (
      <div className={ss.walletsListContent}>
        <div className={ss.walletsList}>
          <img src={nextIdIcon} alt="" />
          <div className={ss.profileItemLabel}>
            <div className={ss.itemLabelTitle}>Next.ID</div>
            <div className={ss.itemLabelText}>{DIDs.nextId}</div>
          </div>
        </div>
        <div className={ss.walletsList}>
          <img src={profileENSIcon} alt="" />
          <div className={ss.profileItemLabel}>
            <div className={ss.itemLabelTitle}>ENS</div>
            {userInfo.ens_name && (
              <div className={ss.itemLabelText}>{userInfo.ens_name}</div>
            )}
          </div>
        </div>
        {DIDs.dotBit.length > 0 ? (
          DIDs.dotBit.map((item, index) => {
            return (
              <div className={ss.walletsList} key={index}>
                <img src={profileDotBitIcon} alt="" />
                <div className={ss.profileItemLabel}>
                  <div className={ss.itemLabelTitle}>.bit</div>
                  <div className={ss.itemLabelText}>{item}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={ss.walletsList}>
            <img src={profileDotBitIcon} alt="" />
            <div className={ss.profileItemLabel}>
              <div className={ss.itemLabelTitle}>.bit</div>
            </div>
          </div>
        )}
        {DIDs.nameTag.length > 0 ? (
          DIDs.nameTag.map((item, index) => {
            return (
              <div className={ss.walletsList} key={index}>
                <img src={profileNameTagIcon} alt="" />
                <div className={ss.profileItemLabel}>
                  <div className={ss.itemLabelTitle}>Nametag</div>
                  <div className={ss.itemLabelText}>{`@${item}`}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={ss.walletsList}>
            <img src={profileNameTagIcon} alt="" />
            <div className={ss.profileItemLabel}>
              <div className={ss.itemLabelTitle}>NameTag</div>
            </div>
          </div>
        )}
      </div>
    );
  }, [DIDs, userInfo]);

  const RenderAccountsList = useCallback(() => {
    const loginUserInfo = getUserInfoByJWT();
    return (
      <div className={ss.walletsListContent}>
        <div className={ss.walletsList}>
          <img
            style={{ width: "24px", height: "auto", borderRadius: "0" }}
            src={phoneIcon}
          />
          <div className={ss.profileItemLabel}>
            <div className={ss.itemLabelAccountTitle}>Phone</div>
            <div className={ss.itemLabelText}>Coming Soon</div>
          </div>
        </div>

        <div className={ss.walletsList}>
          <img
            style={{ width: "24px", height: "auto", borderRadius: "0" }}
            src={emailIcon}
          />
          <div className={ss.profileItemLabel}>
            <div className={ss.itemLabelAccountTitle}>Email</div>
            <div className={ss.itemLabelText}>
              {store.userEmail || "Coming Soon"}
            </div>
            {loginUserInfo &&
            userInfo.user_id == loginUserInfo.user_id &&
            !store.userEmail ? (
              <div
                className={ss.connectAccountIcon}
                onClick={() => {
                  setShowModal(true);
                  setPageType(ACCOUNT_CONNECT_TYPE.EMAIL);
                }}
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={connectMoreAccountIcon}
                  alt=""
                />
              </div>
            ) : null}
          </div>
        </div>
        {accountsConfigs.map((item, index) => {
          return (
            <div className={ss.walletsList} key={index}>
              <img src={item.defaultIcon} alt="" />
              <div className={ss.profileItemLabel}>
                <div className={ss.itemLabelAccountTitle}>{item.title}</div>
                <div className={ss.itemLabelText}>
                  {userInfo[`${item.platform}_username`]
                    ? "@" + userInfo[`${item.platform}_username`]
                    : "Coming Soon"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [userInfo, store.userEmail]);

  const RenderUserInfo = useCallback(() => {
    return (
      <div
        className={cx(ss.userInfoBox, {
          [ss.mobileUserInfoBox]: isMobile,
        })}
      >
        <div style={{ zIndex: 999 }} className={ss.avatarBox}>
          <img
            className={ss.avatarImg}
            src={getUserAvatar(userInfo) || userIcon}
            alt=""
          />
        </div>
        <div className={ss.usernameBox}>
          <div className={ss.username}>
            @{getUserNickName(userInfo)}
            {/*{userInfo.is_business_user && (*/}
            <img src={isBusinessUserIcon} alt="" />
            {/*)}*/}
          </div>
          <div className={ss.walletBox}>
            <div className={ss.wallet}>
              {userInfo.ens_name
                ? userInfo.ens_name
                : getShortAddressByAddress(userInfo.eth_wallet_address)}
              <img
                src={copyIcon}
                onClick={() => {
                  copy(userInfo.eth_wallet_address);
                  present("Copied", 1000);
                }}
                className={ss.copyIcon}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [isMobile, userInfo]);

  const handleFollow = async () => {
    store.setShowLoading(true);
    const roomData = await ChatApi.getRooms({
      user_id: userInfo.user_id,
    });
    await store.getMyRooms(userInfo.user_id)
    store.setShowLoading(false);
    if (roomData.data) {
      present("Follow Success", 1000);
      store.setShowLoading(false);
    }
  };
  const handleSendMessage = () => {
    window.open("https://c.web3messaging.online/");
  };

  const RenderAuditBox = useCallback(() => {
    const loginUserInfo = getUserInfoByJWT();
    if (!loginUserInfo) {
      return null;
    }
    if (loginUserInfo.user_id === store.userInfo?.user_id) {
      return null;
    }
    return (
      <div className={ss.profileAuditButtonBox}>
        <IonButton
          className={ss.followButton}
          expand="block"
          onClick={handleFollow}
          disabled={store.isFollowed}
        >
          <img src={followIcon} alt="" />
          { store.isFollowed ? 'Followed': 'Follow' }
        </IonButton>
        <IonButton
          className={ss.messageButton}
          expand="block"
          onClick={handleSendMessage}
        >
          <img src={sendMessageIcon} alt="" />
          Messages
        </IonButton>
      </div>
    );
  }, [userInfo, store.loginUserInfo, store.isFollowed]);

  return (
    <div className={ss.content}>
      <RenderUserInfo />
      <RenderAuditBox />

      <div
        className={cx(ss.tabs, {
          [ss.mobileTabs]: isMobile,
        })}
      >
        <div
          className={cx(ss.tabsItem, {
            [ss.tabsItemChecked]: segmentValue === "1",
          })}
          onClick={() => {
            setSegmentValue("1");
          }}
        >
          DIDs
        </div>
        <div
          className={cx(ss.tabsItem, {
            [ss.tabsItemChecked]: segmentValue === "2",
          })}
          onClick={() => {
            setSegmentValue("2");
          }}
        >
          NFTs
        </div>
        <div
          className={cx(ss.tabsItem, {
            [ss.tabsItemChecked]: segmentValue === "3",
          })}
          onClick={() => {
            setSegmentValue("3");
          }}
        >
          Accounts
        </div>
      </div>
      <div className={ss.segmentContentBox}>
        {segmentValue === "1" && <RenderDidsList />}
        {segmentValue === "2" && (
          <div className={ss.nftListContent}>
            <div className={ss.nftItemBox}>
              {userNFTs.length > 0 &&
                userNFTs.map((item, index) => {
                  return (
                    <div className={ss.nftItemCard} key={index}>
                      <img src={item.nft.image} alt="" />
                    </div>
                  );
                })}
            </div>
            <IonInfiniteScroll
              onIonInfinite={loadData}
              threshold="30px"
              disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data"
              />
            </IonInfiniteScroll>
          </div>
        )}
        {segmentValue === "3" && <RenderAccountsList />}
      </div>

      <IonModal
        isOpen={showModal}
        //@ts-ignore
        cssClass={ss.modal}
        onDidDismiss={() => {
          setShowModal(false);
        }}
      >
        <ConnectAccountModal type={pageType} closeModal={setShowModal} />
      </IonModal>
    </div>
  );
});
export default MemberProfileModal;
