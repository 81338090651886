import {
  UserInfoInterface,
} from "../services/api/apiInterface/chatInterface";
import { PLATFORM_ENUM } from "./enum";
import { tokenMgr } from "../services/axios/axios";
import moment from "moment";
import Web3 from "web3";
import { API_ROOT_URL } from "./index";
import detectEthereumProvider from "@metamask/detect-provider";
// @ts-ignore
const web3 = new Web3(window.ethereum);

export function isMobile() {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
}

export function getUserAvatar(
  userInfo: UserInfoInterface,
  platform: PLATFORM_ENUM = PLATFORM_ENUM.TWITTER
) {
  let platforms = [
    PLATFORM_ENUM.TWITTER,
    PLATFORM_ENUM.OPENSEA,
    PLATFORM_ENUM.DISCORD,
  ];
  let userAvatar: string = "";
  if (isMobile() && platform === PLATFORM_ENUM.TWITTER) {
    return `${API_ROOT_URL}/avatars/${userInfo.user_id}`;
  }

  if (userInfo[`${platform}_avatar`]) {
    userAvatar = userInfo[`${platform}_avatar`] || "";
  } else {
    let findPlatform = platforms.find((item) => !!userInfo[`${item}_avatar`]);
    if (findPlatform) {
      userAvatar = userInfo[`${findPlatform}_avatar`] || "";
    }
  }
  return userAvatar;
}

export function getUserNickName(
  userInfo: UserInfoInterface,
  platform: PLATFORM_ENUM = PLATFORM_ENUM.TWITTER
) {
  if (!userInfo) return "Unnamed";
  if (userInfo[`${platform}_username`]) {
    return userInfo[`${platform}_username`];
  }
  // if (userInfo.ens_name && userInfo.ens_name.length > 0) {
  //   return userInfo.ens_name;
  // }
  let platforms = [
    PLATFORM_ENUM.TWITTER,
    PLATFORM_ENUM.OPENSEA,
    PLATFORM_ENUM.DISCORD,
  ];
  let userName: string = "";
  let findPlatform = platforms.find((item) => !!userInfo[`${item}_username`]);
  if (findPlatform) {
    userName = userInfo[`${findPlatform}_username`] || "";
  }
  if (!userName) {
    userName = getShortAddressByAddress(userInfo.eth_wallet_address);
  }
  return userName;
}

export function getUserInfoByJWT() {
  let accessToken = tokenMgr().getToken();
  if (accessToken) {
    let jwtToken = accessToken.substring(7);
    let tokenArr = jwtToken.split(".");
    return parseJwt(tokenArr[1]);
  }
  return null;
}

export function getShortAddressByAddress(address: string) {
  let strLength = address.length;
  return (
    address.substring(0, 5) +
    "..." +
    address.substring(strLength - 4, strLength)
  );
}

export function parseJwt(str: string) {
  return JSON.parse(
    decodeURIComponent(
      escape(window.atob(str.replace(/-/g, "+").replace(/_/g, "/")))
    )
  );
}

export function isLogin(): boolean {
  let accessToken = tokenMgr().getToken();
  if (!accessToken) return false;
  let jwtToken = accessToken.substring(7);
  let tokenArr = jwtToken.split(".");
  let tokenObj = parseJwt(tokenArr[1]);
  let accessExpiredAt = tokenObj.access_expired_at * 1000;
  return !moment.utc().isAfter(moment(accessExpiredAt).utc());
}

export async function getEthAccount() {
  const provider = await detectEthereumProvider()
  let res = {
    address: "",
    balance: 0,
    shortAddress: "",
  };
  let accounts = []
  // @ts-ignore
  let isSafePal = provider?.isSafePal;
  // @ts-ignore
  let isMetaMask = provider.isMetaMask;

  if (isSafePal) {
    //@ts-ignore
    try {
      accounts = await web3.eth.requestAccounts();
    } catch (e) {
      console.log(e);
    }
  }
  if (isMetaMask && isMobile()) {
    // @ts-ignore
    accounts = await provider.request({
      method: "eth_requestAccounts",
    });
  }

  if (accounts.length <=0) {
    // 网页
    // @ts-ignore
    const permissionRes = await web3.currentProvider
        // @ts-ignore
        .request({
          method: "wallet_requestPermissions",
          params: [{ eth_accounts: {} }],
        })
        .catch((e: any) => {
          console.log(e);
        });
    if (!permissionRes) return res;
    // @ts-ignore
    accounts = await provider.request({
      method: "eth_requestAccounts",
    });
  }
  if (accounts && accounts.length > 0) {
    res.address = accounts[0];
    let strLength = accounts[0].length;
    res.shortAddress =
        accounts[0].substring(0, 5) +
        "..." +
        accounts[0].substring(strLength - 4, strLength);
  }
  return res;
}

export const randomString = (e: number) => {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
      a = t.length,
      n = "";
  for (let i = 0; i < e; i++) {
    n += t.charAt(Math.floor(Math.random() * a))
  }
  return n;
};