import axiosApiInstance from "../axios/axios";
import {
  GetContactsParams,
  GetContactsResponse,
  GetLoginRandomSecretParams,
  GetLoginRandomSecretResponse,
  LoginParams,
  LoginResponse,
} from "./apiInterface/chatInterface";
import {CONTACT_SETTING_ENUM} from "../../constant/enum";

export interface GetUserInfoParams {
  platform: string;
  user_name: string;
}

export interface GetUserInfoResponse {
  data: any;
  code: number;
  msg: string;
}

export async function getUserInfo(
  params: GetUserInfoParams
): Promise<GetUserInfoResponse> {
  return await axiosApiInstance.post("/info", params);
}

export async function getContacts(
  params: GetContactsParams
): Promise<GetContactsResponse> {
  return await axiosApiInstance.get(`/contacts/${params.page}/${params.size}`);
}

export async function getFollowers(
  params: GetContactsParams
): Promise<GetContactsResponse> {
  return await axiosApiInstance.post("/users/followers", params);
}

export async function register(params: any): Promise<any> {
  return await axiosApiInstance.post("/register", params);
}

export async function getLoginRandomSecret(
  params: GetLoginRandomSecretParams
): Promise<GetLoginRandomSecretResponse> {
  return await axiosApiInstance.post("/login_random_secret", params);
}

export async function login(params: LoginParams): Promise<LoginResponse> {
  return await axiosApiInstance.post("/login", params);
}

export async function getSelfAccounts(params: {
  accountType: string;
  page: number;
  size: number;
}): Promise<any> {
  return await axiosApiInstance.get(
    `/users/accounts/${params.accountType}/${params.page}/${params.size}`
  );
}

export async function getUserAccounts(params: {
  userId: string;
  accountType: string;
  page: number;
  size: number;
}): Promise<any> {
  return await axiosApiInstance.get(
    `/users/${params.userId}/accounts/${params.accountType}/${params.page}/${params.size}`
  );
}
export interface NOTIFICATION_SETTINGS_TYPE {
  mail: boolean
  sms: boolean
  web: boolean
}

export interface GetUserSettingsResType {
  user_id: string
  contact_settings: CONTACT_SETTING_ENUM
  notification_settings: NOTIFICATION_SETTINGS_TYPE
  created_at: any
}

export async function getUserSettings(): Promise<{
  code: number
  msg: string
  data: GetUserSettingsResType
}> {
  return await axiosApiInstance.get("/my_settings");
}

export interface UserSettingType {
  notification_settings: NOTIFICATION_SETTINGS_TYPE;
  contact_settings: string;
}

export interface UserSettingType {
  notification_settings: NOTIFICATION_SETTINGS_TYPE;
  contact_settings: string;
}

export async function updateUserSetting(params: UserSettingType): Promise<any> {
  return await axiosApiInstance.patch("/my_settings", params);
}
