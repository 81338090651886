import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import headerLogo from "../../assert/svg/headerLogo.svg";

import ss from "./index.module.scss";
import { useStore } from "../../services/mobx/service";
import {
  getShortAddressByAddress,
  getUserAvatar,
  getUserInfoByJWT,
  isLogin,
} from "../../constant/utils";
import { PAGE_TYPE } from "../../constant/enum";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import useLogin from "../../hooks/useLogin";

interface IAppProps {
  isMobile: boolean;
}

const size = 20;
const Header: React.FC<IAppProps> = (props) => {
  const store = useStore();
  const history = useHistory();
  const { keys, signMetaMask, init } = useLogin()
  const { isMobile } = props;
  const handleLoginOut = () => {
    store.logout();
  };
  const handleSubscribers = async () => {
    store.setShowModal(true);
    store.setPageType(PAGE_TYPE.SUBSCRIBERS);
    if (store.contacts.length <= 0 || store.followers.length <= 0) {
      await store.getContacts(1, size);
      await store.getFollowers(1, size);
    }
  };

  const handleSetting = async () => {
    await store.getUserSetting();
    store.setShowModal(true);
    store.setPageType(PAGE_TYPE.SETTING);
  };

  const handleProfile = async () => {
    const loginUserInfo = getUserInfoByJWT();
    if (loginUserInfo && loginUserInfo.eth_wallet_address) {
      history.push(`/home/${loginUserInfo.eth_wallet_address}`);
      await store.getUserInfo(loginUserInfo.eth_wallet_address);
    }
  };

  const menus = useMemo(
    () => [
      {
        title: "Subscribers",
        fn: handleSubscribers,
      },
      {
        title: "Setting",
        fn: handleSetting,
      },
      {
        title: "Logout",
        fn: handleLoginOut,
      },
    ],
    [handleLoginOut, handleSubscribers]
  );

  const handleLogin = async () => {
    await store.login();
    if (!keys) {
      await init()
      await signMetaMask()
    }
  };

  const RenderLoginBar = useCallback(() => {
    if (isLogin()) {
      const loginUserInfo = getUserInfoByJWT();
      const userAvatar = getUserAvatar(loginUserInfo);
      return (
        <div className={ss.headRight}>
          <div className={ss.userInfo}>
            {!isMobile && (
              <div
                className={ss.name}
              >
                <div className={ss.successConnectedBox}></div>
                {`Connected to ${getShortAddressByAddress(
                loginUserInfo.eth_wallet_address
              )}`}</div>
            )}
            <div className={ss.avatar}>
              <img src={userAvatar} alt="" />
            </div>
          </div>
          <div className={ss.popover}>
            <ul>
              {isMobile && (
                <li className={ss.popoverItem}>
                  <div className={ss.successConnectedBox}></div>
                  {`Connected to ${getShortAddressByAddress(
                    loginUserInfo.eth_wallet_address
                  )}`}
                </li>
              )}
              {isLogin() && (
                <li className={ss.popoverItem} onClick={handleProfile}>
                  Profile
                </li>
              )}
              {menus.map((item) => {
                return (
                  <li
                    className={ss.popoverItem}
                    key={item.title}
                    onClick={item.fn}
                  >
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
    return (
      <div className={ss.connectBtn} onClick={handleLogin}>
        Connect wallet
      </div>
    );
  }, [store.loginUserInfo, isMobile]);
  return (
    <div
      className={cx(ss.header, {
        [ss.mobileHeader]: isMobile,
      })}
    >
      <div
        className={cx(ss.headerBox, {
          [ss.mobileHeaderBox]: isMobile,
        })}
      >
        <div className={ss.headLeft}>
          <img src={headerLogo} alt="" />
        </div>
        <RenderLoginBar />
      </div>
    </div>
  );
};

export default observer(Header);
