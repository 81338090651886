import { useMemo, useState } from 'react';
import { Client, KeyPairsType } from 'web3-mq';
import {useStore} from "../services/mobx/service";

const useLogin = () => {
  const store = useStore()
  const hasKeys = useMemo(() => {
    const PrivateKey = localStorage.getItem('PRIVATE_KEY') || '';
    const PublicKey = localStorage.getItem('PUBLICKEY') || '';
    const userid = localStorage.getItem('USERID') || '';
    if (PrivateKey && PublicKey && userid) {
      return { PrivateKey, PublicKey, userid };
    }
    return null;

  }, []);

  const [keys, setKeys] = useState<KeyPairsType | null>(hasKeys);
  const [fastestUrl, setFastUrl] = useState<string | null>(null);

  const init = async () => {
    const fastUrl = await Client.init({
      connectUrl: localStorage.getItem('FAST_URL'),
      app_key: 'vAUJTFXbBZRkEDRE',
      env: 'dev',
    });
    localStorage.setItem('FAST_URL', fastUrl);
    setFastUrl(fastUrl);
  };

  const signMetaMask = async () => {
    const { PrivateKey, PublicKey, userid } =
      await Client.register.signMetaMask({
        signContentURI: 'https://www.web3mq.com',
      });
    localStorage.setItem('PRIVATE_KEY', PrivateKey);
    localStorage.setItem('PUBLICKEY', PublicKey);
    localStorage.setItem('USERID', userid);
    setKeys({ PrivateKey, PublicKey, userid });
    const client = Client.getInstance({ PrivateKey, PublicKey, userid });
    await store.setClient(client);
    // const email = await client.user.getUserBindDids()
    // if (store.userEmail) {
    //   if (email && email.length === 0) {
    //     await client.user.userBindDid({
    //       provider_id: EMAIL_PROVIDER_ID,
    //       did_type: "email",
    //       did_value: store.userEmail,
    //     })
    //     // await openToast('Please check you email', 2000)
    //   }
    // }
  };

  const logout = () => {
    localStorage.clear();
    setKeys(null);
  };

  return { keys, fastestUrl, init, signMetaMask, logout };
};

export default useLogin;
