import React, { Suspense, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from './pages/Home'

import { observer } from "mobx-react-lite";

/* Core CSS required for Ionic components to work properly */
import "./App.css";
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import {getUserInfoByJWT} from "./constant/utils";
// @ts-ignore
const App: React.FC = observer(() => {
  setupIonicReact({
    mode: 'ios',
  });


  useEffect(() => {
    const init = async () => {
      // @ts-ignore
      if (getUserInfoByJWT()) {
        let userInfo = getUserInfoByJWT();
        if (userInfo.eth_wallet_address) {
          document.title = `SwapChat ${userInfo.eth_wallet_address}`;
        }
      }
    };
    init()
  }, []);

  //get s
  return (
    <IonApp>
      <IonContent className={'app-content'}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Suspense fallback={<div>Loading</div>}>
              <Route
                path='/'
                render={() => <Redirect to='/home/0x03de7209b82264a951f9d66b8167088f1787c561' />}
                exact={true}
              />
              <Route path='/home/:address' component={Home} />
            </Suspense>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonContent>
    </IonApp>
  );
});

export default App;
