import React, { useCallback, useEffect, useMemo, useState } from "react";
import ss from "./index.module.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { observer } from "mobx-react-lite";
import { closeOutline } from "ionicons/icons";
import * as ChatApi from "../../services/api/chat";

import successIcon from "../../assert/svg/bindAccountSuccessIcon.svg";
import { useStore } from "../../services/mobx/service";
import { getUserInfoByJWT } from "../../constant/utils";
import { EMAIL_PROVIDER_ID } from "src/constant/enum";

export enum ACCOUNT_CONNECT_TYPE {
  PHONE = "phone",
  EMAIL = "email",
}

interface IAppProps {
  type: ACCOUNT_CONNECT_TYPE;
  closeModal: any;
}

const pageConfig = {
  [ACCOUNT_CONNECT_TYPE.EMAIL]: {
    headerTitle: "Connect to your email",
    contentTitle:
      "We’ll send you a one-time verification code to comfirm your email.",
    stepTwoContentTitle: "Please enter 6-digit code sent to you email",
    successTitle: "Your email is verified",
    successSubTitle:
      "Congratulation! Your email successfully links to your SwapChat.",
  },
  [ACCOUNT_CONNECT_TYPE.PHONE]: {
    headerTitle: "Connect to your phone number",
    contentTitle:
      "We’ll text you a one-time verification code to comfirm your number.",
    stepTwoContentTitle: "Please enter 6-digit code sent to you phone",
    successTitle: "Your phone is verified",
    successSubTitle:
      "Congratulation! Your phone number successfully links to your SwapChat.",
  },
};

const ConnectAccountModal: React.FC<IAppProps> = observer((props) => {
  const { type, closeModal } = props;
  const store = useStore();

  const [step, setStep] = useState<number>(1);
  const [inputValue, setInputValue] = useState("");
  const [codeValue, setCodeValue] = useState("");

  const sendAccountConnectCode = async () => {
    const sendRes = await ChatApi.sendBindEmailCode({
      verify_type: type,
      target_address: inputValue,
    });
    if (store.client) {
      await store.client.user.userBindDid({
        provider_id: EMAIL_PROVIDER_ID,
        did_type: "email",
        did_value: inputValue,
      });
    }


    setStep(2);
  };
  const verifyAccountConnect = async () => {
    const sendRes = await ChatApi.verifyEmailCode({
      verify_type: type,
      target_address: inputValue,
      verify_code: codeValue,
    });
    setStep(3);
  };

  const RenderContent = useCallback(() => {
    if (step === 1) {
      return (
        <div className={ss.contentBox}>
          <div className={ss.contentTitle}>{pageConfig[type].contentTitle}</div>
          <div className={ss.inputBox}>
            <div className={ss.inputHeader}>{type}</div>
            <IonInput
              className={ss.input}
              value={inputValue}
              onIonChange={(e) => {
                let value = e.detail.value!;
                setInputValue(value);
              }}
              placeholder="Input your email"
              type="email"
            />
          </div>
        </div>
      );
    }
    return null;
  }, [closeModal, setStep, step, inputValue, setInputValue]);

  const handleConnectSuccess = async () => {
    const loginUserInfo = getUserInfoByJWT();
    await store.getUserEmail(loginUserInfo.user_id);
    closeModal();
  };

  const isEmail = useMemo(() => {
    return /^[a-zA-Z0-9_.-\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
      inputValue
    );
  }, [inputValue]);

  return (
    <IonPage className={ss.page}>
      <IonHeader className={ss.header}>
        <IonToolbar>
          <IonTitle>{pageConfig[type].headerTitle}</IonTitle>
          <IonButtons slot="end" className="ion-close-icon done-button">
            <IonButton onClick={closeModal}>
              <IonIcon
                style={{ color: "#000" }}
                slot="icon-only"
                icon={closeOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={ss.content}>
        {step === 1 && (
          <div className={ss.contentBox}>
            <div className={ss.contentTitle}>
              {pageConfig[type].contentTitle}
            </div>
            <div className={ss.inputBox}>
              <div className={ss.inputHeader}>{type}</div>
              <IonInput
                className={ss.input}
                value={inputValue}
                onIonChange={(e) => {
                  let value = e.detail.value!;
                  setInputValue(value);
                }}
                placeholder="Input your email"
                type="email"
                onIonBlur={() => {}}
              />
            </div>
          </div>
        )}

        {step === 2 && (
          <div className={ss.contentBox}>
            <div className={ss.contentTitle}>
              {pageConfig[type].stepTwoContentTitle}
            </div>
            <div className={ss.inputBox}>
              <div className={ss.inputHeader}>Code</div>
              <IonInput
                className={ss.input}
                value={codeValue}
                onIonChange={(e) => {
                  let value = e.detail.value!;
                  setCodeValue(value);
                }}
                placeholder="000-000"
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className={ss.contentBox}>
            <div className={ss.successBox}>
              <img src={successIcon} alt="" />
              <div className={ss.successTitle}>
                {pageConfig[type].successTitle}
              </div>
              <div className={ss.successSubTitle}>
                {pageConfig[type].successSubTitle}
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <IonFooter className={ss.footer}>
        {step == 1 && (
          <IonButton
            className={ss.actionButton}
            expand="block"
            disabled={!isEmail}
            onClick={sendAccountConnectCode}
          >
            Next
          </IonButton>
        )}
        {step == 2 && (
          <IonButton
            className={ss.actionButton}
            expand="block"
            disabled={!codeValue}
            onClick={verifyAccountConnect}
          >
            Verify
          </IonButton>
        )}

        {step == 3 && (
          <IonButton
            className={ss.actionButton}
            expand="block"
            onClick={handleConnectSuccess}
          >
            Great
          </IonButton>
        )}
      </IonFooter>
    </IonPage>
  );
});
export default ConnectAccountModal;
