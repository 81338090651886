import axios from "axios";
import { API_ROOT_URL } from "src/constant";
import moment from "moment";
import { parseJwt } from "../../constant/utils";

const refreshAPIUrl = `${API_ROOT_URL}/refresh`;

// const [present, dismiss] = useIonToast();

export function tokenMgr() {
  return {
    getToken: (type: "access" | "refresh" = "access") => {
      let accessToken = localStorage.getItem(type);
      return accessToken ? accessToken : "";
    },
    setToken: (v: string, type: "access" | "refresh" = "access") => {
      if (v) {
        localStorage.setItem(type, `Bearer ${v}`);
      } else {
        localStorage.setItem(type, "");
      }
    },
  };
}

console.log(process.env.NODE_ENV, "env");
console.log(process.env.REACT_APP_NODE_ENV, "REACT_APP_NODE_ENV");
console.log(API_ROOT_URL, "API_ROOT_URL-axios");
const axiosApiInstance = axios.create();
axiosApiInstance.defaults.timeout = 100000;
axiosApiInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosApiInstance.defaults.baseURL = API_ROOT_URL;
axiosApiInstance.defaults.withCredentials = false;
// 对响应数据进行处理
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async function (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error.response && [401, 422].includes(error.response.status)) {
      if (originalRequest.url === refreshAPIUrl) {
        return Promise.reject(error);
      } else if (!originalRequest._retry) {
        console.log("_retry", "============");
        let config = {
          headers: {
            Authorization: tokenMgr().getToken("refresh"),
          },
        };
        originalRequest._retry = true;
        return axiosApiInstance
          .post(refreshAPIUrl, {}, config)
          .then((result: any) => {
            if (result.data.access_token) {
              tokenMgr().setToken(result.data.access_token);
              if (result.data.refresh_token) {
                tokenMgr().setToken(result.data.refresh_token, "refresh");
              }
              axiosApiInstance.defaults.headers.common["Authorization"] =
                tokenMgr().getToken();
              return axiosApiInstance(originalRequest);
            }
          })
          .catch((err) => {
            if (
              err.response.status === 401 &&
              originalRequest.url === refreshAPIUrl
            ) {
              return Promise.reject(error);
            }
            // refresh  请求失败，返回登录页面
            window.location.href = "/error";
            return;
          });
      } else {
        if (error.response.data && error.response.data.code === 1) {
          // 后端正常抛异常
          return error.response.data;
        } else {
          return Promise.reject(error);
        }
      }
    }
    if (error.response && error.response.status === 400) {
      if (error.response.data && error.response.data.code === 1) {
        // 后端正常抛异常
        return error.response.data;
      } else {
        return Promise.reject(error);
      }
    }
    if (error.response && error.response.status === 404) {
      return {
        code: -999,
        msg: "api does not exist",
      };
    }
    if (error.response && error.response.status === 500) {
      return {
        code: -999,
        msg: "Internal Server Error",
      };
    }
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    let accessToken = tokenMgr().getToken();
    if (accessToken) {
      let jwtToken = accessToken.substring(7);
      let tokenArr = jwtToken.split(".");
      let tokenObj = parseJwt(tokenArr[1]);
      let accessExpiredAt = tokenObj.access_expired_at * 1000;
      // if (moment.utc().isAfter(moment(accessExpiredAt).utc())) {
      //   // ready to refresh token
      //   console.log("ready to refresh token");
      //   const token = await axios.get(refreshAPIUrl, {
      //     headers: {
      //       Authorization: accessToken,
      //     },
      //   });
      //   if (token.data.data.access_token) {
      //     tokenMgr().setToken(token.data.data.access_token);
      //   }
      // }
      if (moment.utc().isAfter(moment(accessExpiredAt).utc())) {
        // ready to refresh token
        console.log("ready to refresh token");
        let token: any = undefined
          try {
            token = await axios.get(refreshAPIUrl, {
                  headers: {
                      Authorization: accessToken,
                  },
              });
          } catch (e) {
              console.log(e)
          }
        if (token && token.data && token.data.data && token.data.data.access_token) {
          tokenMgr().setToken(token.data.data.access_token);
        } else  {
            tokenMgr().setToken('')
            window.location.href = '/chat/auth'
        }
      }
      newConfig.headers["Authorization"] = tokenMgr().getToken();
    }
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosApiInstance;
