import axiosApiInstance from "../axios/axios";
import {GetRoomsParams, GetRoomsResponse} from "./apiInterface/chatInterface";

export async function getNextIdUserInfo(params: {
  platform: string;
  username: string;
}): Promise<any> {
  return await axiosApiInstance.get(
    `https://proof-service.next.id/v1/proof?platform=${params.platform}&identity=${params.username}`
  );
}

// 获取用户nft列表
export async function getUserNFTList(params: {
  user_id: string;
  wallet_address: string;
  page: number;
  size: number;
}) {
  return await axiosApiInstance.post(`/users/${params.user_id}/nfts`, {
    wallet_address: params.wallet_address,
    page: params.page,
    size: params.size,
  });
}

// 获取.bit
export async function getUserDotBits(params: {
  user_id: string;
  wallet_address: string;
}) {
  return await axiosApiInstance.post(`/users/${params.user_id}/dotbits`, {
    wallet_address: params.wallet_address,
  });
}

// 获取nametag
export async function getUserNameTag(params: {
  user_id: string;
  wallet_address: string;
}) {
  return await axiosApiInstance.post(`/users/${params.user_id}/nametags `, {
    wallet_address: params.wallet_address,
  });
}


// 发送邮箱验证码
export async function sendBindEmailCode(params: {
  verify_type: string,
  target_address: string,

}) {
  return await axiosApiInstance.post(`/send_verify_code`, params);
}
// 验证邮箱验证码
export async function verifyEmailCode(params: {
  verify_type: string,
  target_address: string,
  verify_code: string,

}) {
  return await axiosApiInstance.post(`/verify_code`, params);
}


export async function getRooms(
    params: GetRoomsParams
): Promise<GetRoomsResponse> {
  return await axiosApiInstance.post("/rooms", params);
}

// 获取房间信息，用来判断是否是已经关注
export async function getMyRooms(
    params: {
      user_id: string
    }
): Promise<any> {
  return await axiosApiInstance.post("/my_rooms", params);
}

