export const EMAIL_PROVIDER_ID = "web3mq:email:SwapChat";

export enum PAGE_TYPE {
  PROFILE = "profile",
  SUBSCRIBERS = "subscribers",
  SETTING = "setting",
}

export enum PLATFORM_ENUM {
  TWITTER = "twitter",
  DISCORD = "discord",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  OPENSEA = "opensea",
}

export enum CONTACT_SETTING_ENUM {
  ANYONE = "anyone",
  FRIENDS = "friends",
  VERIFIED_USERS = "verified_users",
}
