import React, { useEffect, useState } from "react";

import ss from "./index.module.scss";
import { IonLoading, IonModal } from "@ionic/react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../services/mobx/service";
import { RouteComponentProps } from "react-router";
import MemberProfileModal from "../../components/MemberProfileModal";
import ProfileModal from "../../components/ProfileModal";
import Header from "../Header";
import HomePageSkeleton from "../../components/Skeletons/HomePageSkeleton/HomePageSkeleton";
import { PAGE_TYPE } from "../../constant/enum";
import { Client } from "web3-mq";
import useLogin from "../../hooks/useLogin";

interface MatchParams {
  address: string;
}

/**
 *  粉丝和主播聊天
 */
const Home: React.FC<RouteComponentProps<MatchParams>> = observer((props) => {
  const address = props.match.params.address;
  const store = useStore();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const { keys, init } = useLogin();
  useEffect(() => {
    if (!store.userInfo) {
      store.getUserInfo(address).then();
    }
  }, [store.userInfo, address]);

  const initRender = async () => {
    await init();
    if (keys) {
      console.log("连接web3-mq");
      const client = Client.getInstance(keys);
      await store.setClient(client);
    }
  };

  useEffect(() => {
    // 保证事件只挂载一次  避免重复render
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 600);
    });
    initRender();
  }, []);
  return (
    <div className={ss.profileContainer}>
      <Header isMobile={isMobile} />

      <div className={ss.profileContentBox}>
        <div className={ss.profileContent}>
          {!store.userInfo ? (
            <HomePageSkeleton />
          ) : (
            <MemberProfileModal userInfo={store.userInfo} isMobile={isMobile} />
          )}
        </div>
      </div>
      <IonModal
        isOpen={store.showModal}
        //@ts-ignore
        cssClass={ss.modal}
        onDidDismiss={() => {
          store.setShowModal(false);
          store.setPageType(PAGE_TYPE.PROFILE);
        }}
      >
        <ProfileModal />
      </IonModal>
      <IonLoading
        isOpen={store.showLoading}
        onDidDismiss={() => store.setShowLoading(false)}
        message={"Loading"}
      />
    </div>
  );
});
export default Home;
