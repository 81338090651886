import React, { useCallback, useEffect, useState } from "react";
import ss from "./index.module.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonToggle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { useStore } from "src/services/mobx/service";
import { observer } from "mobx-react-lite";
import cx from "classnames";
import userIcon from "../../assert/svg/user.svg";
import {getUserAvatar, getUserNickName, randomString} from "../../constant/utils";
import { CONTACT_SETTING_ENUM, PAGE_TYPE } from "../../constant/enum";
import { useHistory } from "react-router-dom";
import { closeOutline } from "ionicons/icons";
import { UserSettingType } from "../../services/api/profile";

const size = 20;

enum TAB_TYPE {
  CONTACTS = "contacts",
  FOLLOWERS = "followers",
  SEND_METHOD = "send_method",
  SEND_AUTH = "send_auth",
}

const tabConfig = {
  [PAGE_TYPE.SUBSCRIBERS]: {
    title: "Subscribers",
    value: PAGE_TYPE.SUBSCRIBERS,
  },
  [PAGE_TYPE.SETTING]: {
    title: "Setting",
    value: PAGE_TYPE.SETTING,
  },
  [PAGE_TYPE.PROFILE]: {
    title: "Profile",
    value: PAGE_TYPE.PROFILE,
  },
};

const pageTabConfig = {
  [PAGE_TYPE.SETTING]: [
    {
      title: "Send Method",
      value: TAB_TYPE.SEND_METHOD,
    },
    {
      title: "Send Auth",
      value: TAB_TYPE.SEND_AUTH,
    },
  ],
  [PAGE_TYPE.SUBSCRIBERS]: [
    {
      title: "Followers",
      value: TAB_TYPE.FOLLOWERS,
    },
    {
      title: "Contacts",
      value: TAB_TYPE.CONTACTS,
    },
  ],
  [PAGE_TYPE.PROFILE]: [],
};

enum SETTING_VALUE_ENUM {
  METHOD_EMAIL = "method_email",
  METHOD_PHONE = "method_phone",
  METHOD_WEB = "method_web",
  // METHOD_CHAT = "method_chat",
  AUTH_ANYONE = "auth_anyone",
  AUTH_FRIENDS = "auth_friends",
  AUTH_VERIFIED = "auth_verified",
}

const settingMethodToggleConfig = [
  { title: "Email", key: SETTING_VALUE_ENUM.METHOD_EMAIL },
  { title: "Phone", key: SETTING_VALUE_ENUM.METHOD_PHONE },
  { title: "Web", key: SETTING_VALUE_ENUM.METHOD_WEB },
  // { title: "Chat", key: SETTING_VALUE_ENUM.METHOD_CHAT },
];
const settingAuthToggleConfig = [
  { title: "Anyone can send", key: SETTING_VALUE_ENUM.AUTH_ANYONE },
  {
    title: "Only friends can send but anyone can send message request",
    key: SETTING_VALUE_ENUM.AUTH_FRIENDS,
  },
  {
    title: "Only verified friends can send",
    key: SETTING_VALUE_ENUM.AUTH_VERIFIED,
  },
];

const ProfileModal: React.FC = observer(() => {
  const store = useStore();
  const history = useHistory();
  const [present] = useIonToast();
  const [tabType, setTabType] = useState<TAB_TYPE>();
  const [settingValue, setSettingValue] = useState<{
    method_email: boolean;
    method_phone: boolean;
    method_web: boolean;
    auth_anyone: boolean;
    auth_friends: boolean;
    auth_verified: boolean;
  }>({
    method_email: store.userSetting.notification_settings.mail,
    method_phone: store.userSetting.notification_settings.sms,
    method_web: store.userSetting.notification_settings.web,
    auth_anyone:
      store.userSetting.contact_settings === CONTACT_SETTING_ENUM.ANYONE,
    auth_friends:
      store.userSetting.contact_settings === CONTACT_SETTING_ENUM.FRIENDS,
    auth_verified:
      store.userSetting.contact_settings ===
      CONTACT_SETTING_ENUM.VERIFIED_USERS,
  });
  const [hashSettingValue, setHashSettingValue] = useState("");

  useEffect(() => {
    if (store.pageType === PAGE_TYPE.SUBSCRIBERS) {
      setTabType(TAB_TYPE.FOLLOWERS);
    } else {
      setTabType(TAB_TYPE.SEND_METHOD);
    }
  }, []);

  const handleSubmit = async () => {
    let contactSettings: CONTACT_SETTING_ENUM = settingValue.auth_anyone
      ? CONTACT_SETTING_ENUM.ANYONE
      : settingValue.auth_friends
      ? CONTACT_SETTING_ENUM.FRIENDS
      : settingValue.auth_verified
      ? CONTACT_SETTING_ENUM.VERIFIED_USERS
      : CONTACT_SETTING_ENUM.FRIENDS;
    if (settingValue.auth_anyone) {
      contactSettings = CONTACT_SETTING_ENUM.ANYONE;
    }
    if (settingValue.auth_anyone) {
      contactSettings = CONTACT_SETTING_ENUM.FRIENDS;
    }
    if (settingValue.auth_anyone) {
      contactSettings = CONTACT_SETTING_ENUM.VERIFIED_USERS;
    }
    let updateParams: UserSettingType = {
      notification_settings: {
        web: settingValue.method_web,
        mail: settingValue.method_email,
        sms: settingValue.method_phone,
      },
      contact_settings: contactSettings,
    };
    await store.updateUserSetting(updateParams)
  };

  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);

  const loadData = async (ev: any) => {
    if (tabType === TAB_TYPE.FOLLOWERS) {
      let currentPage = (store.followers.length / size).toFixed(0);
      let item = +currentPage + 1;
      await store.getFollowers(item, size);
      if (store.currentFollowersCount < size) {
        setIsInfiniteDisabled(true);
      }
      ev.target.complete();
    } else {
      let currentPage = (store.contacts.length / size).toFixed(0);
      let item = +currentPage + 1;
      await store.getContacts(item, size);
      if (store.currentContactsCount < size) {
        setIsInfiniteDisabled(true);
      }
      ev.target.complete();
    }
  };

  const RenderSubscribers = useCallback(() => {
    const users =
      tabType === TAB_TYPE.FOLLOWERS ? store.followers : store.contacts;
    return (
      <IonContent className={ss.contactsList}>
        {users.length > 0 &&
          users.map((user: any, index) => (
            <div
              key={index}
              className={ss.contactItem}
              onClick={async () => {
                if (user.eth_wallet_address) {
                  history.push(`/home/${user.eth_wallet_address}`);
                  // store.emptyUserInfo();
                  await store.getUserInfo(user.eth_wallet_address);
                  store.setShowModal(false);
                  store.setPageType(PAGE_TYPE.PROFILE);
                }
              }}
            >
              <img
                className={ss.avatar}
                src={getUserAvatar(user) || userIcon}
                alt=""
              />
              <div className={ss.name}>{getUserNickName(user)}</div>
            </div>
          ))}
        {isInfiniteDisabled && (
          <div style={{ width: "100%", height: "20px" }} />
        )}
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="30px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data"
          />
        </IonInfiniteScroll>
      </IonContent>
    );
  }, [tabType, store.followers, store.contacts, isInfiniteDisabled]);

  const handleToggleChange = (type: SETTING_VALUE_ENUM, value: boolean) => {
    let item = settingValue;
    let authArr = [
      SETTING_VALUE_ENUM.AUTH_ANYONE,
      SETTING_VALUE_ENUM.AUTH_FRIENDS,
      SETTING_VALUE_ENUM.AUTH_VERIFIED,
    ];
    if (authArr.includes(type)) {
      authArr.forEach((key) => {
        item[key] = false;
      });
    }
    item[type] = value;
    let randomStr = randomString(10)

    if (
      !item[SETTING_VALUE_ENUM.AUTH_ANYONE] &&
      !item[SETTING_VALUE_ENUM.AUTH_FRIENDS] &&
      !item[SETTING_VALUE_ENUM.AUTH_VERIFIED]
    ) {
      present("Select at least one", 1000);
      item[type] = true;
    }
    setHashSettingValue(randomStr);
    setSettingValue(item);
  };

  const RenderSetting = useCallback(() => {
    return (
      <div className={ss.profileSetting}>
        {tabType === TAB_TYPE.SEND_METHOD && (
          <div className={ss.settingContent}>
            <div className={ss.settingTitle}>How you get your notifications</div>
            <div className={ss.list}>
              {settingMethodToggleConfig.map((item, index) => {
                return (
                  <div className={ss.item} key={index}>
                    <div className={ss.text}>{item.title}</div>
                    <IonToggle
                      checked={settingValue[item.key]}
                      onIonChange={(e) => {
                        handleToggleChange(item.key, e.detail.checked);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {tabType === TAB_TYPE.SEND_AUTH && (
          <div className={ss.settingContent}>
            <div className={ss.settingTitle}>Who can reach you</div>
            <div className={ss.list}>
              {settingAuthToggleConfig.map((item, index) => {
                return (
                  <div className={ss.item} key={index}>
                    <div className={ss.text}>{item.title}</div>
                    <IonToggle
                      checked={settingValue[item.key]}
                      onIonChange={(e) => {
                        handleToggleChange(item.key, e.detail.checked);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className={ss.settingFooter}>
          <div className={ss.submitButton} onClick={handleSubmit}>
            + Submit
          </div>
        </div>
      </div>
    );
  }, [
    tabType,
    settingValue,
    handleToggleChange,
    handleSubmit,
    hashSettingValue,
  ]);

  return (
    <div className={ss.body}>
      <IonHeader className={ss.header}>
        <IonToolbar>
          <div className={ss.headerTitle}>
            {tabConfig[store.pageType].title}
          </div>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                store.setShowModal(false);
                store.setPageType(PAGE_TYPE.PROFILE);
              }}
            >
              <IonIcon
                style={{ color: "#000" }}
                slot="icon-only"
                icon={closeOutline}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className={ss.content}>
        <div className={ss.subscribersBox}>
          <div className={ss.tabs}>
            {pageTabConfig[store.pageType].map((item, index) => {
              return (
                <div
                  key={index}
                  className={cx(ss.tabsItem, {
                    [ss.tabsItemChecked]: tabType === item.value,
                  })}
                  onClick={() => {
                    setTabType(item.value);
                  }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>

          <div className={ss.pageCommonContent}>
            {store.pageType === PAGE_TYPE.SUBSCRIBERS && <RenderSubscribers />}
            {store.pageType === PAGE_TYPE.SETTING && <RenderSetting />}
          </div>
        </div>
      </div>
    </div>
  );
});
export default ProfileModal;
